@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  position: relative;
  margin-top: 7rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;

  h2 {
    font-size: 2rem;
    max-width: 500px;
    @include media-breakpoint-up('lg') {
      font-size: 3.9rem;
    }
  }
}

.crocodile {
  margin-right: 2rem;

  svg {
    height: 100%;
    width: 177px;
  }
}

.sloth {
  margin-left: 2rem;
  margin-top: 7rem;

  svg {
    height: 100%;
    width: 244px;
  }
}

.crocodileMobile {
  justify-self: flex-start;

  svg {
    width: 121px;
    height: 172px;
  }
}

.slothMobile {
  justify-self: flex-end;

  svg {
    width: 163px;
    height: 132px;
  }
}

.spacer {
  flex: 1;
}

.textStroke {
  background-image: url("/statics/svgs/textHighlight.svg");
  padding: 2px 7px;
  white-space: nowrap;
}

.lines {
  position: relative;
  text-align: center;
  font-size: 1.8rem;
  @include media-breakpoint-up('lg') {
    font-size: 3.5rem;
  }
  padding: 2rem 0 7rem 0;
  @include media-breakpoint-up('lg') {
    padding: 6rem 0 5rem 0;
  }
  overflow: hidden;

  span {
    font-family: $quizado-font-family-base-headers;
  }
}

.line1 {
  background-image: url("/statics/svgs/line1.svg");
  transform: scale(1.1) rotate(-6deg);
  transform-origin: center center;
  padding: 4rem 0;
  margin: 0 -10rem;
  @include media-breakpoint-up('lg') {
    margin: 0;
  }

  div {
    display: inline-block;
    margin-right: 5rem;
    @include media-breakpoint-up('lg') {
      margin-right: 10rem;
    }
  }
}

.line2 {
  background-image: url("/statics/svgs/line2.svg");
  transform: scale(1.1) rotate(15deg);
  transform-origin: center center;
  padding: 4rem 0;
  margin: -1rem -10rem 0;
  @include media-breakpoint-up('lg') {
    margin: 0;
  }

  div {
    display: inline-block;
    margin-right: 5rem;
    @include media-breakpoint-up('lg') {
      margin-right: 10rem;
    }
  }
}

.line3 {
  background-image: url("/statics/svgs/line3.svg");
  transform: scale(1.1) rotate(-3deg);
  transform-origin: center center;
  padding: 5.5rem 0 4rem;
  margin: -3rem -10rem 0;
  @include media-breakpoint-up('lg') {
    margin-right: 0;
  }
  color: $quizado-white
}

.confetti1 {
  position: absolute;
  top: 7rem;
  left: -25rem;
  @include media-breakpoint-up('md') {
    left: -15rem;
  }
  @include media-breakpoint-up('lg') {
    top: 5rem;
    left: -10rem;
  }
  @include media-breakpoint-up('xl') {
    top: 5rem;
    left: 0;
  }
}

.confetti2 {
  position: absolute;
  top: -3rem;
  right: -25rem;
  @include media-breakpoint-up('md') {
    right: -15rem;
  }
  @include media-breakpoint-up('lg') {
    top: 0;
    right: -10rem;
  }
  @include media-breakpoint-up('xl') {
    top: 0;
    right: 0;
  }
}