@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  position: relative;
  margin-top: 19rem;
}

.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  @include media-breakpoint-down('sm') {
    left: -5rem;
    right: -5rem;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.inner {
  position: relative;
  overflow: hidden;
  margin-top: -19rem;
  padding-top: 19rem;
  padding-bottom: 10rem;
}

.walrus {
  position: absolute;
  bottom: 0;
  right: 0;

  svg {
    width: 298px;
    height: 100%;
  }


  @include media-breakpoint-down('sm') {
    bottom: -1rem;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 198px;
      height: 100%;
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: 2rem;
    @include media-breakpoint-up('lg') {
      font-size: 3rem;
    }
  }
}