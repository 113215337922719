@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  position: relative;
}

.background {
  position: absolute;
  left: -5rem;
  right: -5rem;
  top: 0;
  bottom: 0;
  text-align: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

.inner {
  position: relative;
  padding-top: 50px;
  padding-bottom: 200px;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;

  h2 {
    font-size: 2rem;
    @include media-breakpoint-up('lg') {
      font-size: 3rem;
    }
  }
}

.giraffe {
  margin-top: -4rem;
  margin-bottom: -1rem;

  svg {
    height: 100%;
    width: 146px;
  }
}

.giraffeMobile {
  position: absolute;
  right: 1rem;
  bottom: -1rem;

  svg {
    width: 123px;
    height: 210px;
  }
}