@import 'src/assets/styles/stylekit';

.langPickerPreview {
  img {
    height: 16px;
    border: 1px solid $quizado-accent1;
    margin-top: -3px;
  }
}

.langPicker {
  img {
    height: 12px;
    margin-right: 8px;
    margin-left: 8px;
    border: 1px solid $quizado-accent1;
  }
}