@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  @include media-breakpoint-up('lg') {
    min-height: 1000px;
  }
  display: flex;
  align-items: center;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: absolute;
  top: -20%;
  bottom: 0;
  left: -55%;
  right: -55%;
  @include media-breakpoint-up('sm') {
    left: -30%;
    right: -30%;
  }
  @include media-breakpoint-up('md') {
    left: -10%;
    right: -10%;
  }
  @include media-breakpoint-up('lg') {
    left: 0;
    right: 0;
  }

  svg {
    width: 100%;
  }
}