
.element {
  padding: 3rem 1rem;
}
.elementImage {
  box-shadow: 10px 20px 0 0 rgba(46,54,203,0.08);
  border-radius: 4px;
  transform: rotate(-5deg);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: rotate(0deg);
  }
}