@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.header {
  font-size: 2rem;
  @include media-breakpoint-up('lg') {
    font-size: 4rem;
  }
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.wavesContainer {
  margin-top: 2rem;
}

.waves {
  position: relative;
  overflow: hidden;
}

.wavesBackground {
  position: absolute;
  left: -15rem;
  right: -15rem;
  @include media-breakpoint-up('lg') {
    left: -5rem;
    right: -5rem;
  }
  top: 0;
  bottom: 0;
  text-align: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

.wavesCenterBackground {
  position: absolute;
  left: -5rem;
  right: -5rem;
  top: 0;
  bottom: 0;
  text-align: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

.wavesCenter {
  position: relative;
  max-width: 200px;
  @include media-breakpoint-up('lg') {
    max-width: 300px;
  }
  margin: auto;
}

.wavesCenterInner {
  position: relative;
}

.wavesTitle {
  position: relative;
  font-size: 3.5rem;
  margin-top: -1.2rem;
  @include media-breakpoint-up('lg') {
    font-size: 7rem;
    margin-top: -2rem;
  }
}

.ovals {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ovalsMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ovalConfetti {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ovalConfetti1 {
  svg, > div {
    width: 142px;
    @include media-breakpoint-up('lg') {
      width: 304px;
    }
    height: 100%;
  }
}

.ovalConfetti2 {
  svg, > div {
    width: 104px;
    @include media-breakpoint-up('lg') {
      width: 221px;
    }
    height: 100%;
  }
}

.ovalConfetti3 {
  > svg {
    width: 110px;
    @include media-breakpoint-up('lg') {
      width: 177px;
    }
    height: 100%;
  }
}

.ovalConfettiImage {
  position: absolute;
  top: 0;
  bottom: 0;
  //width: 110px;
  //@include media-breakpoint-up('lg') {
  //  width: 177px;
  //}
  //left: 0;
  //right: 0;
}

.lips {
  display: flex;
  align-items: center;
  padding: 3rem 0 0 3.6rem;
  //padding-left: 5.3rem;
  //padding-top: 4.2rem;
  //padding-right: 0.8rem;
  //@include media-breakpoint-up('lg') {
  //  padding-left: 6rem;
  //  padding-top: 9rem;
  //  padding-right: 2.5rem;
  //}
}

.eyes {
  display: flex;
  align-items: center;
  padding: 0 0.3rem 0 0.9rem;
  //padding-right: 3.4rem;
  //padding-top: 0;
  //padding-left: 3.9rem;
  //@include media-breakpoint-up('lg') {
  //  padding-right: 1.4rem;
  //  padding-top: 0.1rem;
  //  padding-left: 1.8rem;
  //}
}

.hand {
  display: flex;
  align-items: center;

  width: 120px;
  @include media-breakpoint-up('lg') {
    width: 290px;
    margin-left: -5rem;
    margin-top: -2rem;
  }
  //margin-top: -2.3rem;
  //margin-left: 0rem;
  //margin-right: 3.7rem;
  //
  //@include media-breakpoint-up('lg') {
  //  margin-top: -3rem;
  //  margin-left: -4rem;
  //  margin-right: 0;
  //}
}

.ovalConfettiOverlay {
  position: absolute;
  top: -1.8rem;
  bottom: -1.9rem;
  //right: -0.1rem;

  margin-right: -4.4rem;
  margin-top: 1.3rem;
  > svg {
    width: 90px;
    @include media-breakpoint-up('lg') {
      width: 177px;
    }
  }
  @include media-breakpoint-up('lg') {
    top: -3.5rem;
    bottom: 0;
    margin-right: -7.1rem;
    margin-top: -0.7rem;
    //right: -4.7rem;
  }

}

.textStroke {
  background-image: url("/statics/svgs/textHighlight.svg");
  padding: 5px 10px;
  white-space: nowrap;
  font-weight: bold;
}

.content {
  font-size: 1.5rem;
  @include media-breakpoint-up('lg') {
    padding: 0 4rem;
    font-size: 1.7rem;
  }

  p {
    @include media-breakpoint-up('lg') {
      margin: 10rem 0;
    }
  }
}

.ovalsMobileInner {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;

  > div, > p {
    flex: 1;
  }
}