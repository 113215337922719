@import '../assets/styles/stylekit';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins/breakpoints';

.link {
  color: $quizado-secondary;
  background-color: $quizado-primary;
  padding: 1rem 2rem;
  margin: 1rem 0;
  display: inline-block;
  border-radius: 16px;

  &:hover {
    color: $quizado-white;
    text-decoration: none;
  }
  @include media-breakpoint-up('lg') {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -0.5rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out, color 0.3s ease-in-out;
    &:hover {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
}

.dark {
  color: $quizado-primary;
  background-color: $quizado-secondary;
}