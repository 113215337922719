@import "../assets/styles/stylekit";

.container {
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.icon {
  font-size: 48px;
  color: $quizado-primary;
  line-height: 48px;
  filter: drop-shadow(-8px 4px 0 rgba(46, 54, 203, 0.08));
}

.title {
  font-family: $quizado-font-family-base-headers;
  letter-spacing: 0;
  font-size: 1.3rem;
  color: $quizado-primary;
  margin-top: 1rem;
}