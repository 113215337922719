@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  position: relative;
  margin-top: 5rem;
  margin-bottom: -34rem;
}

.background {
  position: absolute;
  left: -5rem;
  right: -5rem;
  top: 0;
  bottom: 0;
  text-align: center;

  svg {
    width: 100%;
    height: 100%;
  }
}

.inner {
  position: relative;
  padding-top: 50px;
  padding-bottom: 200px;
  overflow: hidden;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;

  h2 {
    font-size: 2rem;
    @include media-breakpoint-up('lg') {
      font-size: 3rem;
    }
  }
}

.characters {
  margin-left: -20%;
  margin-right: 5rem;

  svg {

    width: 50vw;
  }
}

.charactersMobile {
  margin-top: -12rem;
  @include media-breakpoint-up('md') {

    margin-top: -6rem;
  }

  svg {
    width: 40vw;
    height: 100%;
  }
}

.content {
  display: flex;
  flex-direction: row;
  margin-top: 5rem;
  font-size: 1.7rem;
}

.list {
  text-align: center;
  font-size: 1.7rem;
  margin-top: 2rem;
  li {
    margin: 1rem 0;
  }
}