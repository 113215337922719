@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.element {
  min-width: 25%;
  height: 100px;
  margin: 20px;
  text-align: center;
  display: flex;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color:transparent;

  &:hover {
    color:transparent;
  }
}

.image {
}