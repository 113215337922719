@import "../../../assets/styles/stylekit";


.inner {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.filledOval {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
  }
;
}

.oval {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    max-width: 200px;
    width: 100%;
  }
}

.elementText {
  position: absolute;
  top: 30%;
  left: 25%;
  right: 25%;
  bottom: 30%;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $quizado-white;
  text-align: center;
  font-weight: bold;

  span {
    color: $quizado-secondary;
  }

}