@import '../assets/styles/stylekit';
@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins/breakpoints';

.links {
  font-weight: bold;
  font-size: 1.2rem;
}

.socials {
  margin: 0 -2rem;
}

.social {
  color: $quizado-primary;
  font-size: 3.4rem;
  margin: 0 2rem;

  svg {
    filter: drop-shadow(-6px 0 0 rgba($quizado-primary, 0.08));
  }
}

.copyright {
  opacity: 0.6;
  text-align: center;
  margin-top: 3rem;
}