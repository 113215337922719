@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container {
  min-height: 100vh;
  height: 100%;
}

.wrapper {
  min-height: 775px;
}

.header {
  max-width: 400px;
}

.description {
  font-size: 1.8rem;
  color: $quizado-primary;
  font-weight: bold;
}

.freeToStart {
  font-size: 1.6rem;
  font-weight: bold;
}