@import '../../../assets/styles/stylekit';
@import '../../../../node_modules/bootstrap/scss/functions';
@import '../../../../node_modules/bootstrap/scss/variables';
@import '../../../../node_modules/bootstrap/scss/mixins/breakpoints';

.ovalSalmon {
  background-image: url("/statics/svgs/oval-salmon.svg");
  padding: 8rem 4rem 7rem 8rem;
  font-size: 1.7rem;
  margin: 0 -4rem 0 -8rem;
  @include media-breakpoint-up('sm') {
    margin: 0;
  }

  span {
    font-weight: bold;
  }
}

.sweatyFace {
  display: flex;
  align-items: center;
  margin-top: 8rem;
  margin-right: -3rem;
  min-width: 135px;

  @include media-breakpoint-down('md') {
    max-width: 135px;
    margin-top: -5rem;
    margin-right: 0;
    align-self: center;
    z-index: 1;
  }
}

.tv {
  display: flex;
  align-items: flex-start;
  margin-left: -1rem;
  margin-top: 2rem;
  min-width: 200px;

  @include media-breakpoint-down('md') {
    max-width: 200px;
    align-self: flex-end;
    margin: 0 10rem -6rem 0;
  }
}

.middleTitle {
  font-size: 4rem;
  max-width: 700px;
  margin: auto;

  span {
    font-weight: bold;
    background-image: url("/statics/svgs/textHighlight-blue.svg");
    padding: 2px 7px;
  }
}

.secondParagraph {
  padding-top: 10rem;
  @include media-breakpoint-down('md') {
    padding-top: 3rem;
  }
}

.ovalBlue {
  background-image: url("/statics/svgs/oval-blue.svg");
  padding: 7rem 9rem 6rem 12rem;
  font-size: 1.7rem;
  margin: 0 -4rem 0 -8rem;
  @include media-breakpoint-up('sm') {
    margin: 0;
  }

  span {
    font-weight: bold;
  }
}

.face {
  display: flex;
  align-items: flex-start;
  margin-top: -11rem;
  margin-left: -14rem;
  min-width: 292px;

  @include media-breakpoint-down('md') {
    max-width: 135px;
    margin-top: 0;
    margin-right: 0;
    align-self: flex-end;
    z-index: 1;
  }

  @include media-breakpoint-down('xs') {
    margin-right: -8rem;
  }
}

.walrus {
  display: flex;
  align-items: flex-end;
  margin-right: -15rem;
  margin-bottom: -4rem;
  z-index: 2;

  @include media-breakpoint-down('md') {
    max-width: 135px;
    margin-top: -6rem;
    margin-right: 0;
    align-self: center;
    z-index: 1;
  }

  svg {
    width: 178px;
    height: 156px;

    @include media-breakpoint-up('lg') {
      width: 309px;
      height: 271px;
    }
  }
}