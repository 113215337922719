@import '../assets/styles/stylekit';

.mainDownloadButton {
  font-size: 2rem;
}

.downloadButtons {
  a {
    color: $quizado-white;
  }
}
.onDarkBg {
  p {
    color: $quizado-white;
  }
}

.onDarkBgAlsoOn {
  a {
    color: $quizado-white;
    padding: 5px 10px;
    margin: 5px;
    border-radius: 6px;
  }
}


.downloadNativeStoreImage {
  height: 48px;
  margin-bottom: 12px;

  &:hover {
    opacity: 0.8;
  }
}
.downloadButton {
  background-color: $quizado-secondary;
  border-color: $quizado-secondary;
  color: $quizado-primary;

  &:hover {
    color: $quizado-white;
    background-color: darken($quizado-secondary, 5%);
  }

  background-position: 10px center;
  background-repeat: no-repeat;
  padding-left: 50px;
  padding-right: 20px;
  padding-top: .915rem;
  padding-bottom: 0.915rem;
  white-space: nowrap;
}

.macosDownload {
  background-position: 16px 36%;
  background-image: url('/statics/images/download/download-macos.svg');
}

.androidDownload {
  background-image: url('/statics/images/download/download-android.svg');
}

.iosDownload {
  background-image: url('/statics/images/download/download-ios.svg');
}

.linuxDownload {
  background-position-x: 12px;
  background-image: url('/statics/images/download/download-linux.svg');
}

.windowsDownload {
  background-position-x: 14px;
  background-image: url('/statics/images/download/download-windows.svg');
}